import Repository from './repository'

const resource = '/v1/treinamentos/tipos'

export default {
  buscaTipoTreinamento (id) {
    return Repository.get(`${resource}/${id}`)
  },
  listarTiposTreinamento () {
    return Repository.get(`${resource}`)
  },
  salvarTipoTreinamento (item) {
    return Repository.post(`${resource}`, item)
  },
  atualizarTipoTreinamento (id, item) {
    return Repository.put(`${resource}/${id}`, item)
  },
  deletarTipoTreinamento (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
